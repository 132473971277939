import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/deliverymanagement',
    name: 'deliverymanagement',
    component: () => import('@/views/deliverymanagement.vue'),

  },

  {
    path: '/finish',
    component: () => import('@/views/finish.vue'),
  }

]

const router = new VueRouter({
  routes
})

export default router